const options = {
  rootMargin: '0px 0px -20%',
  // threshold: [0, 0.2, 0.4, 0.6, 0.8, 1]
  // threshold: [0.2, 0.8]
  threshold: 0
}

const callback = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
      entry.target.classList.add('-action');
    } else if (entry.intersectionRatio === 0) {
      // entry.target.classList.remove('-action');
    }
  });
}

const observer  = new IntersectionObserver(callback, options);

const observers = [...document.querySelectorAll('.-animTarget')];

observers.forEach(el => {
  el.classList.add('-ready');
  observer.observe(el);
});
