/*
import test from './utils/test';
import igarashi_san from './utils/igarashi_san';
import Person from './class/Person';

test.foo();
test.bar();

igarashi_san();

let es6 = new Person('ECMAScript 2015');
es6.sayHello();
 */

import 'remodal';
import velocity from 'velocity-animate';
import scrollbar from './utils/jquery.trackpad-scroll-emulator.min';
import slide from './utils/slick.min';
import scrollAnim from './utils/scrollAnim';
import kintone from './utils/kintone-custom';
import marketo from './utils/marketo-custom';

// ▼空のリンクを無効化
$(function() {
  $('a[href=""]').click(function(){
    return false;
  });
});

// ▼ページスクロール
$(function(){
  $('a[href^="#"]').click(function() {
    var speed = 500;
    var href= $(this).attr("href");

    if (href != '#modal') {
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    }
  });
});

// ▼SPフッターメニュー
$(function () {
  var ftmenuBtn = $('.ftBody_ttl.-parent .arw');

  ftmenuBtn.on('click', ftmenuBox_open);

  function ftmenuBox_open() {
    $(this).parents('.ftBody_col').siblings().children('.ftBody_list').slideUp().siblings('.ftBody_ttl').removeClass('current');
    $(this).parents('.ftBody_ttl').next('.ftBody_list').slideToggle();
    $(this).parents('.ftBody_ttl').toggleClass('current');
    return false;
  }
});

// ▼Gナビスクロールバー
$(function() {
  $('.l-header_body').TrackpadScrollEmulator({
    autoHide: false
  });
});

// ▼Gナビドロップダウンメニュー
$(function () {
  var hoverMenu = $('.gNav_item > a');
  var timer;
  function subClose() {
    $('.l-header_sub').removeClass('current');
    // console.log('remove!');
  }
  hoverMenu.on('mouseenter focus', function () {
    // $('.gNav_item, .l-header_sub').removeClass('current');
    // $(this).parents('.gNav_item').addClass('current');

    if($(this).parents('.gNav_item').hasClass('-parent')) {
      clearTimeout(timer);
      // console.log('clearA!');

      $('.l-header_sub').empty();
      $('.l-header_sub').addClass('current');
      var headH = $('.l-header_head').outerHeight();
      var itemP = $(this).parent().position().top;
      // console.log(headH +'/'+ itemP);
      $(this).siblings('.gNavSub_list').clone().prependTo($('.l-header_sub')).css('top', headH + itemP - 2);
    }
  });
  $('.gNav_item.-parent > a').on('mouseleave blur', function () {
    timer = setTimeout(subClose,200,'');
    // console.log('timer!');
  });

  $('.l-header_sub').on('mouseenter focus', function () {
    clearTimeout(timer);
    // console.log('clearB!');
    $(this).addClass('current');
    var navNum = $(this).children('.gNavSub_list').data('child');
    $('.gNav_item[data-parent="'+ navNum +'"').addClass('current');
    // console.log(navNum);
  });
  $('.l-header_sub').on('mouseleave blur', function () {
    // $('.l-header_sub').empty();
    $('.gNav_item, .l-header_sub').removeClass('current');
  });

  $('.l-header').on('mouseleave blur', function () {
    $('.gNav_item, .l-header_sub').removeClass('current');
  });
});

// SPメニュー
$(function() {
  $('.gNav_list.-download').clone().appendTo('.nav-sp_menu');
  $('.gNav_list.-main1').clone().appendTo('.nav-sp_menu');
  $('.gNav_list.-main2').clone().appendTo('.nav-sp_menu');
  $('.gNav_list.-inquiry').clone().appendTo('.nav-sp_menu');

  var spBtn = $('.nav-sp_btn a');
  spBtn.on('click', spMenuBox_open);
  function spMenuBox_open() {
    $(this).parent().toggleClass('current').next().slideToggle();
  }
});

$(function () {
  var spSubMenu = $(".nav-sp .gNav_item.-parent > a");
  spSubMenu.on("click", spSubMenuBox_open);

  function spSubMenuBox_open() {
    $(this).parents('.gNav_item').siblings().find(".gNavSub_list").slideUp().parent().removeClass("current");
    $(this).parents('.gNav_list').siblings().find(".gNavSub_list").slideUp().parent().removeClass("current");
    $(this).siblings(".gNavSub_list").slideToggle();
    $(this).parent().toggleClass("current");
    return false;
  }
});

// ▼トップローディング
if(document.querySelector('#loading')) {
  window.onload = function() {
    setTimeout(topStart, 1000);
  }
  function topStart() {
    const spinner = document.getElementById('loading');
    spinner.classList.add('-loaded');
    topSlide();
  }
}

// ▼TOPスライド
function topSlide() {
  var $video = $('#topmov').get(0);
  var seed = Math.random();
  var randKv = seed ? Math.ceil(seed * 2 - 1) : 0;
  $('.topSlide_list').on('init', function(event, slick){
    $video.currentTime = 5;
  });
  var slider = $('.topSlide_list').slick({
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 10000,
    speed: 1000,
    pauseOnFocus: false,
    pauseOnHover: false,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          autoplay: true,
          adaptiveHeight: true,
          autoplaySpeed: 10000,
          speed: 1000,
          pauseOnFocus: false,
          pauseOnHover: false,
          fade: true,
          arrows: false,
          initialSlide:randKv
        }
      }
    ]
  });
  slider.on('afterChange', function(event, slick, currentSlide){
    $video.currentTime = 5;
  });
}

$(function() {
  var footerBtn = $('.topFooter_btn a');
  footerBtn.on('click', footer_on);
  function footer_on() {
    $(this).parents('.topFooter').toggleClass('current');
  }
})

// ▼タブコンテンツ/スライド
$(function() {
  var slider = $('.productServiceTab_slideList').slick({
    infinite: false
  });
  $('.productServiceTab_tab a').click(function() {
    var index = $(this).parent().index();
    $('.productServiceTab_tab').removeClass('current');
    $(this).parent().addClass('current');
    $('.productServiceTab_panel').removeClass('current').eq(index).addClass('current');
    slider.slick('setPosition');// スライダー再描画
  });
});

// ▼導入事例チェックボックス全チェック・ボタンテキスト書き換え・PC/SP版切り替え
$(function() {
  var checkAll = '.checkAll'; //「すべて」のチェックボックスのclassを指定
  var classArray = ['industry','purpose','scale']; // nameで指定
  var array = ['sort-A','sort-B','sort-C']; // idで指定
  $.each(array, function(index, value){
    var checkBox = 'input[id^="'+value+'"]'; //チェックボックスのidを指定
    $( '[id^="'+value+'"]'+checkAll ).on('click', function() {
      $( checkBox+':not('+checkAll+')' ).prop('checked', false );
    });
    console.log('checkBox', checkBox);

    var boxCount = $( checkBox+':not('+checkAll+')' ).length; //全チェックボックスの数を取得
    var checked  = $( checkBox+':not('+checkAll+')' + ':checked' ).length; //チェックされているチェックボックスの数を取得
    $( checkBox ).on( 'click change', function() {
      console.log('boxCount', boxCount);
      if(!$(this).hasClass('checkAll')) {
        if( checked === boxCount ) {
          $( '[id^="'+value+'"]'+checkAll ).prop( 'checked', true );
          $( '[id^="'+value+'"]input:not('+checkAll+')' ).prop( 'checked', false );
          $(this).parents('.caseStudySearch_sub').siblings('.caseStudySearch_select').find('.caseStudySearch_selectInr').text('すべて');//ボタン書き換え
        } else {
          $( '[id^="'+value+'"]'+checkAll ).prop( 'checked', false );
        }
      }

      // ▼ 一つだけチェックされるようにする
      // 複数選択可能に戻す場合は下記2行をコメントアウトし、上の2箇所のコメントアウト/* 〜 */を外す
      // $( checkBox ).prop('checked', false);
      // $(this).prop('checked', true);
      // ▲ 一つだけチェックされるようにする

      var checks = '';
      $('[id^="'+value+'"]:checked').each(function() {
        if(checks) {
          checks = checks + ' / ' + $(this).next().text();
        } else {
          checks = $(this).next().text();
        }
      });
      // console.log('checks', checks);
      if(!checks) {
        checks = '選択してください';
      }
      $(this).parents('.caseStudySearch_sub').siblings('.caseStudySearch_select').find('.caseStudySearch_selectInr').text(checks);//ボタン書き換え
    });

    if($( checkBox+':not('+checkAll+')' + ':checked' ).length > 0) {
      $( checkBox ).trigger( 'change' );
    }
  });
  $(window).on("load resize", init);
  function init(){
    var _width = $(window).width();
    $.each(classArray, function(index, value){
      if(_width <= 768){
        $('.caseStudySearch_sub.-'+value+' .list-sort').appendTo('.remodal_body.-'+value);
      }else{
        $('.remodal_body.-'+value+' .list-sort').appendTo('.caseStudySearch_sub.-'+value);
        if($('[data-remodal-id=modal]').length) {
          $('[data-remodal-id=modal]').remodal().close();
        }
      }
    });
  }
 });

// ▼導入事例チェックボックス一覧表示
 $(function () {
  var caseStudyBtn = $('.caseStudySearch_select a');
  caseStudyBtn.on('click', caseStudyBox_open);
  function caseStudyBox_open() {
    $(this).parents('.caseStudySearch_col').siblings().children('.caseStudySearch_sub').fadeOut().removeClass('current');
    $(this).parent().siblings('.caseStudySearch_sub').fadeToggle();
    $(this).parent().toggleClass('current');
    $(document).on('click touchend', function(event) {
      if (!$(event.target).closest('.caseStudySearch_sub').length) {
        $('.caseStudySearch_sub').fadeOut();
      }
    });
    return false;
  }
});

// ▼LP3331_MV YOUTUBE MODAL
$('.index-main__btn__play').on('click',function(){
  const youtubeID = $(this).attr('data-youtubeid')
  $('.modal-inner').append('<p class="modal-player" />')
  $('.modal-player').html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/'+youtubeID+'?vq=hd1080&showinfo=0&rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>')
  $('.modal').css({display:'block',opacity:0}).velocity({opacity:1},{duration:1000,easing:'easeOutSine'})
})
$('.modal-bg').on('click',function(){
  $('.modal').velocity({opacity:0},{duration:1000,easing:'easeOutSine',complete:function(){
    $(this).css({display:'none'})
    $('.modal-player').remove();
  }})
})
