// Kintoneのカスタマイズ用

// ▼Kintoneのドロップダウンで初期値を未選択「選択してください」にする
(function() {
  "use strict";
  if(typeof fb !== 'undefined') {
    fb.events.form.created = [function (state) {
      // セレクトボックスのdata-vv-nameの値を入れる
      const select_item = [
        'purpose', // お見積もり：利用目的
        'season', // お見積もり：導入予定時間
        'count', // お見積もり：想定利用人数
        'place_of_use' // 写真診断：利用場所
      ];
      select_item.forEach(item => {
        if(typeof record[item] !== 'undefined') {
          // console.log(item);
          state.record[item].value = "";
        }
      });
      return state;
    }];
  }
})();
